// 申遗承诺
import SHZT from './sn-table-column-shzt';
import DJZT from './sn-table-column-djzt';

const selfData = {
    loading: false,
    snTable: {
        table: {
            data: [],
            rowKey: 'ID',
        },
        columns: {
            // 数据列
            columnsData: [
                {
                    label: "年度",
                    prop: "ND",
                    minWidth: ""
                },
                {
                    label: "资源开采点数量(个)",
                    prop: "ZYKCDSL",
                    minWidth: ""
                },
                {
                    label: "严重污染工业企业数量(个)",
                    prop: "YZWRGYQYSL",
                    minWidth: ""
                },
                {
                    label: "当前的遗产区人口数量(人)",
                    prop: "DQYCQRKSL",
                    minWidth: ""
                },
                {
                    label: "人口疏散需求",
                    prop: "RKSSXQ_DESC",
                    minWidth: ""
                },
                // {
                //     label: "农业总值（万元）",
                //     prop: "NYZZ",
                //     minWidth: ""
                // },
                // {
                //     label: "工业总值（万元）",
                //     prop: "GYZZ",
                //     minWidth: ""
                // },
                // {
                //     label: "服务业总值（万元）",
                //     prop: "FWYZZ",
                //     minWidth: ""
                // },
                // {
                //     label: "三大产业比例",
                //     prop: "SDCYBL",
                //     minWidth: ""
                // },
                SHZT,
                DJZT
            ],
            // 操作列
            columnsBtn: {
                buttonData: []
            }
        },
    },
    snSearch: {
        inputData: [{
            label: '关键字',
            placeholder: "请输入关键字",
            value: 'keyword',
            operateType: 'search',
            isShow: true,
            default: ""
        }],
    },
    snButton: {
        buttonData: [
            {
                isShow: true,
                btnType: 'button',
                operateType: 'buttonNew',
                showMethod: 'showNewBtn',
                name: '新建',
                round: true,
                backColor: '#28ccd9',
            },
            {
                btnType: 'button',
                operateType: 'buttonExport',
                showMethod: 'showExportBtn',
                name: '导出',
                round: true,
                backColor: '#ffcc66',
                color: '#fff'
            }
        ]
    }
};
export default selfData;